import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const PinballWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  iframe {
    scale: 1.2;
    margin-top: 50px;
  }
`;

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function Pinball({ onClose, onMinimize, isFocus }) {
  const [iframeSrc, setIframeSrc] = useState('/space-cadet-game/index.html'); // Manage iframe src state
  const height = isMobileDevice() ? "580px" : "500px";

  useEffect(() => {
    if (!isFocus) {
      // If focus is lost, reload the iframe by resetting the src
      setIframeSrc(''); // Temporarily clear src to force reload
      setTimeout(() => {
        setIframeSrc('/space-cadet-game/index.html'); // Restore src to reload iframe
      }, 0); // Reload iframe in the next render cycle
    }
  }, [isFocus]);

  return (
    <PinballWrap>
      <iframe
        title="Pinball"
        frameBorder="0"
        width="100%"
        height={height}
        src={iframeSrc} // Dynamically set src based on state
      ></iframe>
    </PinballWrap>
  );
}

export default Pinball;
