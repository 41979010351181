import React, { useEffect, useRef, useState } from 'react';

function Quake3({ onClose, isFocus }) {
  const iframeRef = useRef(null);
  const [iframeSrc, setIframeSrc] = useState('https://quake.windogexp.net'); // Manage the iframe src state

  // Function to handle focus on the iframe
  const handleFocusIframe = () => {
    if (iframeRef.current) {
      // Focus the iframe to capture keyboard/mouse events again
      iframeRef.current.focus();
    }
  };

  useEffect(() => {
    // Handle iframe reload if focus is lost
    if (!isFocus) {
      setIframeSrc(''); // Temporarily clear the src to force reload
      setTimeout(() => {
        setIframeSrc('https://quake.windogexp.net'); // Restore the original src to reload iframe
      }, 0); // Reload iframe in next render cycle
    } else {
      handleFocusIframe(); // Focus iframe if isFocus becomes true
    }
    console.log(isFocus);
  }, [isFocus]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <iframe
        src={iframeSrc} // Bind iframe src to the managed state
        frameBorder="0"
        title="quake3"
        ref={iframeRef}
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(192,192,192)',
        }}
        tabIndex={-1} // Ensure iframe can be focused programmatically
      />
      {!isFocus && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundColor: 'transparent', // Keep it invisible but clickable
            zIndex: 10, // Overlay on top of iframe
          }}
          onClick={handleFocusIframe} // Focus iframe on click
        />
      )}
    </div>
  );
}

export default Quake3;
